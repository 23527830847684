<template>
  <div class="target-report">
    <div class="tab">
      <a-tabs v-model:activeKey="activeKey">
        <a-tab-pane key="team" tab="团队目标">
          <Team />
        </a-tab-pane>
        <a-tab-pane key="personal" tab="个人目标">
          <Personal />
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref } from "vue";
import Team from "./components/Team";
import Personal from "./components/Personal";

export default defineComponent({
  name: "TargetReport",
  components: { Team, Personal },
  setup() {
    const activeKey = ref("team");
    return {
      activeKey,
    };
  },
});
</script>
<style lang="less" scoped>
@import "~@/style/theme.less";
.target-report {
  margin: 16px;
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  display: flex;
  flex-direction: column;
  :deep(.ant-tabs) {
    font-family: "PingFang SC";
    font-style: normal;
    line-height: 150%;
    color: #2d3748;

    .ant-tabs-bar {
      margin-bottom: 0;
      border-bottom: none;

      .ant-tabs-nav {
        padding: 15.5px 27px;
        background-color: #fff;
        box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.05);
        border-radius: 0 20px 0 0;
      }

      .ant-tabs-extra-content {
        margin-top: 11px;
      }
    }

    .ant-tabs-tabpane {
      background-color: #fff;
      padding: 24px !important;
      height: 82.5vh;
      overflow-y: auto;
    }

    .ant-tabs-tab {
      font-weight: 400;
      font-size: 18px;
      padding: 0px 16px 6px 16px;
      margin-right: 37px;

      &::before {
        transition: unset;
      }

      &.ant-tabs-tab-active {
        color: #2d3748;
        font-weight: 500;
        font-size: 20px;

        &::before {
          position: absolute;
          top: unset;
          bottom: 1px;
          left: 30%;
          width: 40%;
          background: @primary-color;
        }
      }
    }

    .ant-tabs-ink-bar {
      display: none !important;
    }
  }
}
</style>
