<template>
  <section class="app-container">
    <div class="filter mb24">
      <div class="form">
        <div class="label">数据筛选：</div>
        <a-select
          class="item"
          v-model:value="formState.reportType"
          placeholder="请选择"
          show-search
          :options="arveReportOps"
          @change="handleSearch"
        />
        <div class="label">年份：</div>
        <a-date-picker
          class="item"
          mode="year"
          format="YYYY"
          placeholder="请选择"
          :allowClear="false"
          :open="isYearOpen"
          v-model:value="formState.year"
          @openChange="val => isYearOpen = val"
          @panelChange="handleYearChange"
        />
        <a-space>
          <a-button type="primary" @click="handleReset">重置</a-button>
        </a-space>
      </div>
      <a-space>
        <a-button v-auth="['system:arve:teamTarget:export']" type="primary" ghost @click="handleExport">导出</a-button>
        <a-button v-auth="['system:arve:teamTarget:import']" type="primary" @click="handleImport">导入</a-button>
        <a-button v-auth="['system:arve:teamTarget:download']" type="primary" @click="handleImportTemplate">下载模板</a-button>
      </a-space>
    </div>
    <CardTitle size="large" title="团队目标" />
    <a-table
      class="mt24"
      rowKey="id"
      :pagination="false"
      :expandedRowKeys="expandedRowKeys"
      :columns="columns"
      :data-source="reportList"
      :scroll="{
        y: '45vh',
        x: 3000,
        scrollToFirstRowOnChange: true,
      }"
      @expand="handleExpand"
    />
    <import-modal
      v-model:visible="modalVisible"
      apiFuncName="importOrgTargetReport"
      @closeModal="modalVisible = false"
      @resetTable="handleSearch"
    />
  </section>
</template>
<script>
import {
  defineComponent,
  onMounted,
  reactive,
  ref,
  computed,
} from "vue";
import { useGlobalPropertyHook } from "@/hooks/common.js";
import {
  dictionaryOptionsCode,
  downloadFile,
} from "@/utils/common";
import moment from "moment";
import CardTitle from "@/components/CardTitle";
import ImportModal from "@/components/ImportModal";

export default defineComponent({
  name: "Team",
  components: {
    CardTitle,
    ImportModal,
  },
  setup() {
    const { $api } = useGlobalPropertyHook();
    const arveReportOps = ref([]);
    const formState = reactive({
      reportType: undefined,
      year: moment(),
    });

    // 获取筛选框字典
    const getSelectOptions = () => {
      return $api
        .getDictionaryList({
          params: {
            dictType: "arveYearReport",
          },
        })
        .then((res) => {
          arveReportOps.value = dictionaryOptionsCode(res.data.arveYearReport);
          if (arveReportOps.value.length > 0) {
            formState.reportType = arveReportOps.value[0].value;
          }
        });
    };

    const columns = computed(() => {
      const getCols = (title, type) => {
        let months = [ "jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep", "oct", "nov", "dec" ];
        let cols = [ 
          {
            title: () => {
              return (
                <div>
                  <div>Year</div>
                  <div>{title}</div>
                  <div>{type.toUpperCase()}</div>
                  <div>Target</div>
                </div>
              );
            },
            dataIndex: `fy${title}Target`,
            key: `fy${title}Target`,
            ellipsis: true,
            align: "center",
            width: 100,
          },
        ]
        return cols.concat(months.map(month => ({
          title: () => {
            return (
              <div>
                <div>{month.toUpperCase()}</div>
                <div>{title}</div>
                <div>{type.toUpperCase()}</div>
                <div>Target</div>
              </div>
            );
          },
          dataIndex: `${month}${title}Target`,
          key: `${month}${title}Target`,
          customHeaderCell: () => ({
            style: {
              "background-color": "#F3F3F3",
            },
          }),
          customCell: () => ({
            style: {
              "background-color": "#FBFBFB",
            },
          }),
          ellipsis: true,
          align: "center",
          width: 120,
        })));
      }
      return [
        {
          title: "Team",
          dataIndex: "child",
          key: "child",
          ellipsis: true,
          width: 300,
          resizable: true,
        },
        ...getCols("Operation", "Arve"),
        ...getCols("Money", "Arve"),
      ];
    });
    const reportList = ref([]);
    const expandedRowKeys = ref([]);

    const handleExpand = (expanded, record) => {
      if (expanded) {
        expandedRowKeys.value.push(record.id);
      } else {
        expandedRowKeys.value.splice(expandedRowKeys.value.indexOf(record.id), 1);
      }
    };

    const handleSearch = () => {
      $api.getOrgTargetReport({
        reportType: formState.reportType,
        year: formState?.year?.format("YYYY")
      }).then((res) => {
        expandedRowKeys.value = res.data.idList;
        reportList.value = res.data.finalList;
      });
    };
    const handleReset = () => {
      Object.assign(formState, {
        reportType: arveReportOps.value[0]?.value,
        year: moment(),
      });
      handleSearch();
    };
    const modalVisible = ref(false);
    const isYearOpen = ref(false);
    const handleYearChange = (val) => {
      formState.year = val;
      isYearOpen.value = false;
      handleSearch();
    }
    const handleExport = () => {
      $api.exportOrgTargetReport({
        reportType: formState.reportType,
        year: formState?.year?.format("YYYY"),
      }).then((res) => {
        downloadFile(res.file, res.filename);
      });
    };
    const handleImport = () => {
      modalVisible.value = true;
    };
    const handleImportTemplate = () => {
      $api.exportOrgTargetReportTemplate({
        reportType: formState.reportType,
        year: formState?.year?.format("YYYY"),
      }).then((res) => {
        downloadFile(res.file, res.filename);
      });
    };

    onMounted(async () => {
      await getSelectOptions();
      handleSearch();
    });

    return {
      formState,
      arveReportOps,
      columns,
      reportList,
      handleSearch,
      handleExport,
      handleImport,
      handleImportTemplate,
      handleReset,
      handleExpand,
      isYearOpen,
      handleYearChange,
      modalVisible,
      expandedRowKeys,
    };
  },
});
</script>
<style scoped lang="less">
.app-container {
  .filter {
    display: flex;
    justify-content: space-between;
    .form {
      display: flex;
      align-items: center;

      .label {
        //width: 100px;
        margin-right: 16px;
        font-family: "PingFang SC";
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        color: #2d3748;
      }

      .item {
        width: 240px;
        margin-right: 40px;
      }
    }
  }
}
</style>
