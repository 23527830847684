<template>
  <section class="app-container">
    <div class="arve-tip">{{ arveTip }}</div>
    <div class="search">
      <div class="form">
        <div class="label">精确搜索:</div>
        <a-input-search
          class="item"
          allowClear
          placeholder="请输入人名/ID Number"
          @search="handleSearch"
          v-model:value="formState.fullName"
        />
      </div>
      <div>
        <a-space>
          <a-button type="link" @click="showFilter = !showFilter" v-auth="['system:arve:personalTarget:collapse']"
            >筛选项
            <UpOutlined style="font-size: 10px" v-if="showFilter" />
            <DownOutlined style="font-size: 10px" v-else />
          </a-button>
          <a-button type="primary" ghost @click="handleExport" v-auth="['system:arve:personalTarget:export']">导出</a-button>
          <a-button type="primary" @click="handleImport" v-auth="['system:arve:personalTarget:import']">导入</a-button>
          <a-button type="primary" @click="handleImportTemplate" v-auth="['system:arve:personalTarget:download']">下载模板</a-button>
        </a-space>
      </div>
    </div>
    <div v-show="showFilter" class="form mt24">
      <div class="label">职级:</div>
      <a-select
        class="item"
        v-model:value="formState.psGroup"
        :options="staffGradeOps"
        @change="handleSearch"
        placeholder="请选择"
        allowClear
        show-search
      />
      <div class="label">Team:</div>
      <a-select
        class="item"
        mode="multiple"
        :maxTagCount="1"
        v-model:value="formState.orgThreeTwoList"
        placeholder="请选择"
        @change="orgThreeTwoChange"
        allowClear
        show-search
      >
        <a-select-option
          v-for="(item, index) in orgThreeTwoOps"
          :key="index"
          :value="item"
          ><span :title="item">{{ item }}</span>
        </a-select-option>
      </a-select>
      <div class="label">Offering:</div>
      <a-select
        class="item"
        mode="multiple"
        :maxTagCount="1"
        v-model:value="formState.orgFourOneOneList"
        placeholder="请选择"
        @change="handleSearch"
        allowClear
      >
        <a-select-option
          v-for="(item, index) in orgFourOneOneOps"
          :key="index"
          :value="item"
          ><span :title="item">{{ item }}</span>
        </a-select-option>
      </a-select>
    </div>
    <div v-show="showFilter" class="form mt24">
      <div class="label">年份:</div>
      <a-date-picker
        class="item"
        mode="year"
        format="YYYY"
        placeholder="请选择"
        :allowClear="false"
        :open="isYearOpen"
        v-model:value="formState.year"
        @openChange="val => isYearOpen = val"
        @panelChange="handleYearChange"
      />
      <a-button style="margin-left: 42px;" type="primary" @click="handleReset"
        >重置</a-button
      >
    </div>
    <div class="table-header mt24">
      <CardTitle size="large" title="个人目标" />
      <a-space>
        <a-button v-show="editing" type="primary" ghost @click="handleCancel">取消</a-button>
        <a-button v-show="editing" :disabled="saveDisabled" type="primary" @click="handleSave">保存</a-button>
        <a-button :disabled="reportList.length === 0" v-show="!editing" type="primary" @click="handleEdit" v-auth="['system:arve:personalTarget:edit']">编辑</a-button>
      </a-space>
    </div>
    <a-table
      class="mt24"
      rowKey="id"
      :columns="columns"
      :data-source="reportList"
      :pagination="editing ? false : pagination"
      :scroll="{
        x: (columns.length - 8) * 120 + 1000,
        y: tableHeight,
        scrollToFirstRowOnChange: true,
      }"
    />
    <import-modal
      v-model:visible="modalVisible"
      apiFuncName="importPersonalTargetReport"
      @closeModal="modalVisible = false"
      @resetTable="handleSearch"
    />
  </section>
</template>
<script>
import {
  defineComponent,
  onMounted,
  onUnmounted,
  reactive,
  ref,
  computed,
  nextTick
} from "vue";
import { useGlobalPropertyHook } from "@/hooks/common.js";
import {dictionaryOptionsCode, downloadFile, useTableHeight} from "@/utils/common";
import CardTitle from "@/components/CardTitle";
import moment from "moment";
import {
  DoubleLeftOutlined,
  DoubleRightOutlined,
  UpOutlined,
  DownOutlined,
} from "@ant-design/icons-vue";
import ImportModal from "@/components/ImportModal";
import { cloneDeep } from "lodash";
import { message } from "ant-design-vue";

export default defineComponent({
  name: "Personal",
  components: {
    CardTitle,
    // eslint-disable-next-line vue/no-unused-components
    DoubleLeftOutlined,
    // eslint-disable-next-line vue/no-unused-components
    DoubleRightOutlined,
    UpOutlined,
    DownOutlined,
    ImportModal,
  },
  setup() {
    const { $api } = useGlobalPropertyHook();
    const showFilter = ref(false);
    const editing = ref(false);
    const editingCell = ref([]);
    const staffGradeOps = ref([]);
    const orgThreeTwoOps = ref([]);
    const orgFourOneOneOps = ref([]);
    const tableHeight = ref(0);
    const getSize = () => {
      nextTick(() => {
        tableHeight.value = useTableHeight();
      });
    };
    window.addEventListener("resize", getSize);
    const formState = reactive({
      fullName: "",
      psGroup: undefined,
      orgThreeTwoList: [],
      orgFourOneOneList: [],
      year: moment(),
    });
    const getGradeOps = () => {
      $api
        .getDictionaryList({
          params: {
            dictType: "grade",
          },
        })
        .then((res) => {
          staffGradeOps.value = dictionaryOptionsCode(res.data.grade);
        });
    };
    const getOrgList = () => {
      //查询org3.2/org4.2
      $api.getOrgThree_FourList().then((res) => {
        const { orgThreeTwo, orgFourOneOne } = res.data;
        orgThreeTwoOps.value = orgThreeTwo;
        orgFourOneOneOps.value = orgFourOneOne;
      });
    };
    const orgThreeTwoChange = () => {
      formState.orgFourOneOneList = [];
      if (formState.orgThreeTwoList.length) {
        $api
          .getOrgFour({ condition: formState.orgThreeTwoList.join() })
          .then((res) => {
            orgFourOneOneOps.value = res.data.orgFourOneOne;
          });
      } else {
        getOrgList();
      }
      handleSearch();
    };
    const collapse = ref("");
    const columns = computed(() => {
      let infoCols = [];
      const type = 'info';
      if (collapse.value.includes(type)) {
        infoCols = [
          {
            title: () => (
              <div class="collapse-cell">
                <div class="collapse-btn">
                  {
                    collapse.value.includes(type)
                      ? <DoubleLeftOutlined onClick={() => collapse.value = collapse.value.replace(type, "")}/>
                      : <DoubleRightOutlined onClick={() => collapse.value += type} />
                  }
                </div>
              </div>
            ),
            align: "center",
            width: 0,
          },
          {
            title: "Team",
            dataIndex: "orgThreeTwo",
            key: "orgThreeTwo",
            ellipsis: true,
            width: 120,
            resizable: true,
            align: "center",
            customHeaderCell: () => ({
              style: {
                "background-color": "#F3F3F3",
              },
            }),
            customCell: () => ({
              style: {
                "background-color": "#FBFBFB",
              },
            }),
          },
          {
            title: "Offering",
            dataIndex: "orgFourOneOne",
            key: "orgFourOneOne",
            ellipsis: true,
            width: 120,
            resizable: true,
            align: "center",
            customHeaderCell: () => ({
              style: {
                "background-color": "#F3F3F3",
              },
            }),
            customCell: () => ({
              style: {
                "background-color": "#FBFBFB",
              },
            }),
          },
          {
            title: "Grade",
            dataIndex: "psGroup",
            key: "psGroup",
            ellipsis: true,
            width: 80,
            align: "center",
            customHeaderCell: () => ({
              style: {
                "background-color": "#F3F3F3",
              },
            }),
            customCell: () => ({
              style: {
                "background-color": "#FBFBFB",
              },
            }),
          },
          {
            title: "ID Number",
            dataIndex: "idNumber",
            key: "idNumber",
            ellipsis: true,
            width: 100,
            align: "center",
            customHeaderCell: () => ({
              style: {
                "background-color": "#F3F3F3",
              },
            }),
            customCell: () => ({
              style: {
                "background-color": "#FBFBFB",
              },
            }),
          },
        ];
      } else {
        infoCols = [
          {
            title: () => (
              <div class="collapse-cell">
                <div class="collapse-btn">
                  {
                    collapse.value.includes(type)
                      ? <DoubleLeftOutlined onClick={() => collapse.value = collapse.value.replace(type, "")}/>
                      : <DoubleRightOutlined onClick={() => collapse.value += type} />
                  }
                </div>
              </div>
            ),
            align: "center",
            width: 0,
          }
        ];
      }
      const getCols = (type) => {
        const customRender = (month, type) => ({ text, index }) => {
          if (editing.value) {
            let val = reportList.value[index][`${month}${type}Target`];
            if (val === null) {
              val = ""
            } else if (typeof val === "string" && val.includes("%")) {
              val = Number(val.replace("%", ""));
            }
            let isNoError = val !== "" && !isNaN(val) && val >= 0 && val <= 100;
            if (editingCell.value.length && editingCell.value[0] === index && editingCell.value[1] === `${month}${type}Target`) {
              return <a-input-number
                        class={`input-number-wrapper-${isNoError ? "normal" : "error"}`}
                        value={text}
                        onChange={(value) => reportList.value[index][`${month}${type}Target`] = value === 0 ? value : value || ""}
                        formatter={value => `${value}%`}
                      />
            } else {
              return <div
                        class={["input-number-wrapper", `input-number-wrapper-${ isNoError? "normal" : "error"}`]}
                        onClick={() => editingCell.value = [index, `${month}${type}Target`]}
                      >{ `${val}%`}</div>;
            }
          } else {
            return text;
          }
        }
        let months = [ "jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep", "oct", "nov", "dec" ];
        let cols = [
          {
            title: () => {
              return (
                <div>
                  <div>Year</div>
                  <div>{type.toUpperCase()}</div>
                  <div>Target</div>
                </div>
              );
            },
            name: `Year ${type.toUpperCase()} Target`,
            dataIndex: `year${type}Target`,
            key: `year${type}Target`,
            align: "center",
            width: 100,
            customRender: customRender("year", type),
          },
          {
            title: () => (
              <div class="collapse-cell">
                <div class="collapse-btn">
                  {
                    collapse.value.includes(type)
                    ? <DoubleLeftOutlined onClick={() => collapse.value = collapse.value.replace(type, "")}/>
                    : <DoubleRightOutlined onClick={() => collapse.value += type} />
                  }
                </div>
              </div>
            ),
            align: "center",
            width: 0,
          }
        ]
        if (collapse.value.includes(type)) {
          cols = cols.concat(months.map(month => ({
            title: () => {
              return (
                <div>
                  <div>{month.toUpperCase()}</div>
                  <div>{type.toUpperCase()}</div>
                  <div>Target</div>
                </div>
              );
            },
            name: `Year ${type.toUpperCase()} Target`,
            dataIndex: `${month}${type}Target`,
            key: `${month}${type}Target`,
            customHeaderCell: () => ({
              style: {
                "background-color": "#F3F3F3",
              },
            }),
            customCell: () => ({
              style: {
                "background-color": "#FBFBFB",
              },
            }),
            align: "center",
            width: 100,
            customRender: customRender(month, type),
          })));
        }
        return cols;
      }
      return [
        {
          title: "Name",
          dataIndex: "fullName",
          key: "fullName",
          ellipsis: true,
          width: 80,
          resizable: true,
        },
        ...infoCols,
        ...getCols("Arve"),
        ...getCols("Bd"),
        ...getCols("Coe"),
        {
          title: "",
          dataIndex: "empty",
          key: "empty",
          width: 16,
        },
      ];
    });
    const reportList = ref([]);
    const cacheReportList = ref([]);
    const pagination = reactive({
      current: 1,
      pageSize: 10,
      total: 0,
      showSizeChanger: true,
      showTotal: (total) => {
        return `共 ${total} 条`;
      },
      onChange: (page) => {
        pagination.current = page;
        handleSearch(pagination);
      },
      onShowSizeChange: (_, size) => {
        pagination.pageSize = size;
        pagination.current = 1;
        handleSearch(pagination);
      },
    });

    const handleSearch = ({ current = 1, pageSize = pagination.pageSize } = {}) => {
      const params = {
        ...formState,
        year: formState?.year?.format("YYYY"),
        pageIndex: current,
        pageSize: pageSize,
      };
      $api.getPersonalTargetReport(params).then((res) => {
        reportList.value = cloneDeep(res.data.content);
        cacheReportList.value = cloneDeep(res.data.content);
        pagination.total = res.data.total;
        pagination.current = res.data.pageable.page + 1;
        pagination.pageSize = res.data.pageable.size;
      });
    };
    const handleReset = () => {
      Object.assign(formState, {
        fullName: "",
        psGroup: undefined,
        orgThreeTwoList: [],
        orgFourOneOneList: [],
        year: moment(),
      });
      handleSearch();
    };
    const modalVisible = ref(false);
    const isYearOpen = ref(false);
    const handleYearChange = (val) => {
      formState.year = val;
      isYearOpen.value = false;
      handleSearch();
    }
    const handleExport = () => {
      const params = {
        ...formState,
        year: formState?.year?.format("YYYY"),
      };
      $api.exportPersonalTargetReport(params).then((res) => {
        downloadFile(res.file, res.filename);
      });
    };
    const handleImport = () => {
      modalVisible.value = true;
    };
    const handleImportTemplate = () => {
      $api.exportPersonalTargetReportTemplate().then((res) => {
        downloadFile(res.file, res.filename);
      });
    };
    const handleEdit = () => {
      editing.value = true;
      editingCell.value = [];
    };
    const saveDisabled = computed(() =>
      !reportList.value
        .every(item =>
          columns.value
            .filter(({ key }) => key?.endsWith("Target"))
            .every(({ key }) => {
              let val = item[key];
              if (val === null) {
                val = ""
              } else if (typeof val === "string" && val.includes("%")) {
                val = Number(val.replace("%", ""));
              }
              return val !== "" && !isNaN(val) && val >= 0 && val <= 100;
            })));
    const handleSave = () => {
      $api.updatePersonalTargetReport(
        reportList.value.map(item => {
          columns.value.forEach(({ key }) => {
            if(key?.endsWith("Target")) {
              if (!isNaN(item[key])) {
                item[key] = `${item[key]}%`;
              }
            }
          })
          return item;
      })).then(() => {
        editing.value = false;
        editingCell.value = [];
        message.success("保存成功");
        handleSearch();
      });
    };
    const handleCancel = () => {
      editing.value = false;
      editingCell.value = [];
      reportList.value = cloneDeep(cacheReportList.value);
    };
    const arveTip = ref("");
    const getArveTips = () => {
      $api.getArveTips().then(res => {
        arveTip.value = res.data;
      });
    }
    onMounted(() => {
      getArveTips();
      getOrgList();
      getGradeOps();
      handleSearch();
      getSize();
    });
    onUnmounted(() => {
      window.removeEventListener("resize", getSize);
    });

    return {
      showFilter,
      editing,
      editingCell,
      formState,
      staffGradeOps,
      orgThreeTwoOps,
      orgFourOneOneOps,
      orgThreeTwoChange,
      columns,
      pagination,
      reportList,
      handleSearch,
      handleExport,
      handleImport,
      handleImportTemplate,
      handleReset,
      handleEdit,
      handleSave,
      handleCancel,
      isYearOpen,
      handleYearChange,
      modalVisible,
      collapse,
      saveDisabled,
      arveTip,
      tableHeight,
      getSize
    };
  },
});
</script>
<style scoped lang="less">
@import "~@/style/theme.less";
.app-container {
  .search {
    display: flex;
    justify-content: space-between;
  }
  .form {
    display: flex;
    align-items: center;

    .label {
      width: 100px;
      margin-right: 16px;
      text-align: right;
      font-family: "PingFang SC";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      color: #2d3748;
    }

    .item {
      width: 240px;
      margin-right: 40px;
    }
  }
  .table-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &:deep(.collapse-cell) {
    position: relative;
    .collapse-btn {
      width: 28px;
      height: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background: rgba(49, 130, 206, 0.2);
      color: @primary-color;
      position: absolute;
      left: -28px;
    }
  }
  &:deep(.input-number-wrapper) {
    width: 4.6875vw;
    margin: auto;
    border: 1px solid @primary-color;
  }
  &:deep(.input-number-wrapper-normal) {
    border-color: @primary-color !important;
  }
  &:deep(.input-number-wrapper-error) {
    border-color: #DC5E4F !important;
  }
}

.arve-tip {
  margin-bottom: 10px;
  text-align: right;
  color: #f59723;
}
</style>
